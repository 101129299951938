import { notification } from "antd";
import { EmitirErro } from "../../services/EmitirErro";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { api } from "../../services/apiAxios";
import { urlLimitesETaxas, urlLimitesETaxasMunicipios } from "../../services/urls";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/globalContext";
import { mask, unMask } from "../../utils/MascaraDeCampos";

interface DadosCidadesAll {
    "idIbge": number,
    "nome": string,
    "cnpj": string | null,
}

export const useLimitesETaxas = () => {

    const navigate = useNavigate();
    const refForm = useRef<any>(null);

    const { theme, updateTheme } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [msgEmail, setMsgEmail] = useState(false);

    const refDataListCidades = useRef<Array<DadosCidadesAll>>([]);
    const [dataListCidades, setDataListCidades] = useState<Array<DadosCidadesAll>>([]);
    const [loadingListCidades, setLoadingListCidades] = useState(false);

    const [readonlyCNPJ, setReadonlyCNPJ] = useState(true);

    //setando dados das cidades no selected
    const getMunicipios = useCallback(async () => {

        try {
            setLoadingListCidades(true);
            let resultCidade = await api.get(urlLimitesETaxasMunicipios);

            refDataListCidades.current = resultCidade.data
            setDataListCidades(resultCidade.data);

            setLoadingListCidades(false);

        } catch (error) {
            EmitirErro(error, true, 'municipios_dkwdfaiweyi')
        }

    }, []);

    useEffect(() => {

        if (theme === 'dark') {
            updateTheme('light')
            window.location.reload();
        }

        getMunicipios();

    }, [theme])

    const onSubmit = useCallback((): void => {
        const buscaDeDados = async (dados: any) => {
            try {
                setLoading(true);
                let result = await api.post(urlLimitesETaxas, dados);

                refForm.current?.resetFields();

                setMsgEmail(true);

            } catch (error) {
                setLoading(false);

                EmitirErro(error, true, 'login_dkwdfaiweyi')
            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {
                // values.cnpj = unMask(values.cnpj)
                delete values.cnpj
                values.cpf = unMask(values.cpf)

                values.telefoneFixo = unMask(values.telefoneFixo)
                values.celular = unMask(values.celular)

                buscaDeDados(values);

            })
            .catch(() => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const onChangeInputCNPJ = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "99.999.999/9999-99"
        ]);

        refForm.current.setFieldsValue(
            { cnpj: maskedValue }
        );
        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["cnpj"])
        }

    }, []);

    const onChangeInputCPF = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "999.999.999-9",
            "999.999.999-99"
        ]);

        refForm.current.setFieldsValue(
            { cpf: maskedValue }
        );
        if (maskedValue.length >= 10) {
            refForm.current.validateFields(["cpf"])
        }

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { telefoneFixo: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["telefoneFixo"])
        }

    }, []);

    const onChangeInputCelular = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { celular: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["celular"])
        }

    }, []);

    const encontrarObjCidadePeloID = useCallback((id?: number | string | null) => {
        let resultadoCidade = refDataListCidades.current.filter((cidade) => cidade.idIbge == id)

        if (resultadoCidade.length > 0) {

            return resultadoCidade[0]
        }
        return undefined
    }, []);

    const onChangeSelectCidades = useCallback((id: number) => {
        const objCity = encontrarObjCidadePeloID(id)

        if (objCity &&
            objCity?.cnpj &&
            objCity.cnpj != null
        ) {

            const originalValue = unMask(objCity.cnpj);
            const maskedValue = mask(originalValue, [
                "99.999.999/9999-99"
            ]);

            refForm.current.setFieldsValue(
                { cnpj: maskedValue }
            );

            setReadonlyCNPJ(true)

            return
        }

        setReadonlyCNPJ(false)

    }, []);


    return {
        loading,
        theme,
        refForm,
        onSubmit,
        msgEmail,
        navigate,

        dataListCidades,
        loadingListCidades,

        onChangeInputCNPJ,
        onChangeInputCPF,
        onChangeInputTelefone,
        onChangeInputCelular,
        onChangeSelectCidades,
        readonlyCNPJ,
        setMsgEmail,
        setLoading,
    }
}
