import { Form, Input, Button, Row, Col, Select, Spin } from 'antd';

// @ts-ignore
import ImagemLogoSPWHITE from './../../assets/imagens/SP_DESENVOLVIMENTO_ECONOMICO_WHITE.png';
// @ts-ignore
import ImagemLogoSP from './../../assets/imagens/SP_DESENVOLVIMENTO_ECONOMICO.png';
// @ts-ignore
import ImagemLogoDSP from './../../assets/imagens/DESENVOLVE_SP_LOGO_EM_CIMA.png';
// @ts-ignore
import ImagemLogoDSPWhite from './../../assets/imagens/DESENVOLVE_SP_LOGO_EM_CIMA_WHITE.png';

import { ReactComponent as EmailSVG } from './../../assets/imagens/svgs/email.svg';
import { ReactComponent as PersonSVG } from './../../assets/imagens/svgs/person.svg';
import { ReactComponent as TelefoneSVG } from './../../assets/imagens/svgs/telefone.svg';
import { ReactComponent as WhatsAppSVG } from './../../assets/imagens/svgs/whatsapp.svg';
import { ReactComponent as CheckBlueSVG } from './../../assets/imagens/svgs/checkblue.svg';

import './style.css';
import { Loading } from '../../components/Loading';
import { useLimitesETaxas } from './useLimitesETaxas';
import { removeAcento } from '../../utils/RemoveAcentos';
import { unMask } from '../../utils/MascaraDeCampos';
import { isValidCPF } from '../../utils/validations';


import { RiFileList2Line } from "react-icons/ri";

const colFull = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

const col12 = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

const LimitesETaxas = () => {

    const {
        loading,
        theme,
        refForm,
        onSubmit,
        msgEmail,
        navigate,


        dataListCidades,
        loadingListCidades,

        onChangeInputCNPJ,
        onChangeInputCPF,
        onChangeInputTelefone,
        onChangeInputCelular,
        onChangeSelectCidades,
        readonlyCNPJ,
        setMsgEmail,
        setLoading,
    } = useLimitesETaxas();

    return (
        <>
            <Loading
                loading={loading === true && msgEmail === false}
            />
            <Row
                gutter={24}
                style={{
                    // maxHeight: "100vh",
                    margin: 0,

                    display: 'flex',
                    minHeight: '100vh',
                    alignItems: 'stretch'
                }}
                align="middle"
            >

                <Col
                    className='fotoFundoPreCadastro_fvewqlefhjgvb'
                    style={{
                        filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',
                    }} flex={1}
                >
                    <div
                        className='cssformPreCadastro_cewolvbCOLibeofrqa'
                    >

                        <div
                            className={`${msgEmail ? 'divImgsMobileSempre_frhvbqwyuofvbqwf' : 'divImgsMobile_frhvbqwyuofvbqwf'}`}
                        >
                            <img
                                className='imagemLogo_cewolvbCOLibeofrqa'
                                src={ImagemLogoDSPWhite}
                            ></img>
                            <img
                                className='imagemLogoSP_cewolvbCOLibeofrqa'
                                src={ImagemLogoSPWHITE}
                            ></img>
                        </div>

                        {
                            !msgEmail && (
                                <Form
                                    name="formPreCadastro"
                                    ref={refForm}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1
                                    }}
                                    layout="vertical"

                                    onFinish={onSubmit}
                                >
                                    <div

                                        className='divTitleAndForm_fewkhfgvqfioyu'
                                    >
                                        <h1
                                            style={{
                                                // textAlign: 'center',
                                                fontWeight: 'bold',
                                                color: '#FFF',
                                                lineHeight: 1.2,
                                                // marginTop: 20
                                                // marginLeft: 10,
                                                // marginRight: 10,

                                            }}
                                            className='font-personalizada font-title_ewfkjhvqwrwegf largura-title_ewfkjhvqwrwegfa fontSizeTitleLimitesETaxasPrincipal_efcwhfgkvqwe'
                                        >Formulário para verificação de taxa de juros e limite <span style={{ whiteSpace: 'nowrap' }}>pré-aprovado.</span></h1>
                                        <div
                                            className='divFormulario_cewolvbCOLibeofrqa divFormularioWitdhResponsivo_cewolvbCOLibeofrqa'
                                        >

                                            <div
                                                className='divImgsDesktop_frhvbqwyuofvbqwf'
                                            >
                                                <img
                                                    className='imagemLogo_cewolvbCOLibeofrqa'
                                                    src={ImagemLogoDSP}
                                                ></img>
                                                <img
                                                    className='imagemLogoSP_cewolvbCOLibeofrqa'
                                                    src={ImagemLogoSP}
                                                ></img>
                                            </div>
                                            <Row>

                                                <Col {...colFull}>

                                                    <Form.Item
                                                        // label="Município"
                                                        name="idIbge"
                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Por favor, selecione o município.',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='selectLimitesETaxas font-personalizada'
                                                            showSearch
                                                            // prefix={<UserOutlined style={{ color: '#848484' }} />}
                                                            placeholder="Município"
                                                            optionFilterProp="children"
                                                            size='large'

                                                            onChange={onChangeSelectCidades}
                                                            loading={loadingListCidades}
                                                            notFoundContent={loadingListCidades ? <Spin size="small" /> : null}
                                                            // disabled={!hasSelectedAdd}
                                                            // defaultValue='Usuários'
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            filterOption={(input: any, option: any) => {
                                                                let textDigit = removeAcento(input)
                                                                let listCidade = removeAcento(option?.children);
                                                                return listCidade.indexOf(textDigit) >= 0
                                                            }}
                                                        >
                                                            {
                                                                dataListCidades.map((item) => {
                                                                    return (
                                                                        <Select.Option
                                                                            className={'font-personalizada'}
                                                                            value={item.idIbge}
                                                                            key={item.idIbge.toString()}
                                                                        >
                                                                            {item.nome}
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>

                                                </Col>



                                                <Col {...colFull}>

                                                    <Form.Item
                                                        // label="Nome"
                                                        name="nome"
                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Por favor, digite o seu nome.',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size='large'
                                                            className='inputsLimitesETaxas'
                                                            prefix={<PersonSVG style={{ color: '#848484' }} />}
                                                            placeholder="Nome"
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col {...colFull}>

                                                    <Form.Item
                                                        // label="E-mail"
                                                        name="email"
                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                type: 'email',
                                                                message: 'Por favor, digite o e-mail',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size='large'
                                                            className='inputsLimitesETaxas'
                                                            prefix={<EmailSVG style={{ color: '#848484' }} />}
                                                            placeholder="E-mail"
                                                        />
                                                    </Form.Item>

                                                </Col>


                                                <Col {...colFull}>

                                                    <Form.Item
                                                        // label="Telefone Fixo"
                                                        name="telefoneFixo"
                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Por favor, digite o telefone',
                                                            },
                                                            () => ({
                                                                validator(rule, value) {
                                                                    return new Promise((resolve: (value?: any) => void, reject) => {

                                                                        if (value != '' && value != null) {
                                                                            value = unMask(value);
                                                                            if (value.length >= 10) {
                                                                                resolve();
                                                                            } else {
                                                                                reject("Telefone inválido");
                                                                            }
                                                                        } else {
                                                                            reject('');
                                                                        }
                                                                    });
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            size='large'
                                                            className='inputsLimitesETaxas'
                                                            prefix={<TelefoneSVG style={{ color: '#848484' }} />}
                                                            placeholder="Telefone Fixo"
                                                            onChange={onChangeInputTelefone}
                                                            inputMode='numeric'
                                                        />
                                                    </Form.Item>

                                                </Col>

                                                <Col {...colFull}>

                                                    <Form.Item
                                                        // label="CNPJ"
                                                        name="cnpj"
                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Por favor, digite o cnpj.',
                                                            },
                                                            // () => ({
                                                            //     validator(rule, value) {
                                                            //         return new Promise((resolve: (value?: any) => void, reject) => {

                                                            //             if (value && value != '' && value != null) {
                                                            //                 value = unMask(value);

                                                            //                 if (value.length >= 14) {
                                                            //                     if (isValidCNPJ(value)) {
                                                            //                         resolve();
                                                            //                     } else {
                                                            //                         reject("CNPJ inválido");
                                                            //                     }
                                                            //                 } else {
                                                            //                     reject("CNPJ inválido");
                                                            //                 }
                                                            //             } else {
                                                            //                 reject("CNPJ inválido");
                                                            //             }
                                                            //         });
                                                            //     },
                                                            // }),
                                                        ]}
                                                    >
                                                        <Input
                                                            size='large'
                                                            className='inputsLimitesETaxas'
                                                            placeholder="CNPJ"
                                                            onChange={(e) => onChangeInputCNPJ(e)}
                                                            readOnly={true}
                                                            prefix={
                                                                <RiFileList2Line color='#848484' size={'1.1rem'} />
                                                            }
                                                        // disabled
                                                        // readOnly={readonlyCNPJ}
                                                        />
                                                    </Form.Item>

                                                </Col>

                                                <Col {...colFull}>
                                                    <Form.Item
                                                        // label="CPF"
                                                        name="cpf"
                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Por favor, digite o cpf',
                                                            },
                                                            () => ({
                                                                validator(rule, value) {
                                                                    return new Promise((resolve: (value?: any) => void, reject) => {
                                                                        //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                                        if (value != '' && value != null) {
                                                                            value = unMask(value);

                                                                            if (value.length >= 10) {

                                                                                if (isValidCPF(value)) {
                                                                                    resolve();
                                                                                } else {
                                                                                    reject("CPF inválido");
                                                                                }

                                                                            } else {
                                                                                reject("CPF inválido");
                                                                            }
                                                                        } else {
                                                                            reject("CPF inválido");
                                                                        }
                                                                    });
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            size='large'
                                                            className='inputsLimitesETaxas'
                                                            prefix={
                                                                <RiFileList2Line color='#848484' size={'1.1rem'} />
                                                            }
                                                            placeholder="CPF"
                                                            onChange={onChangeInputCPF}
                                                            inputMode='numeric'
                                                        />
                                                    </Form.Item>
                                                </Col>


                                                <Col {...colFull}>

                                                    <Form.Item
                                                        // label="Celular (WhatsApp)"
                                                        name="celular"

                                                        style={{
                                                            margin: 5,
                                                            marginBottom: 15
                                                        }}
                                                        className='font-personalizada'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Por favor, digite o celular',
                                                            },
                                                            () => ({
                                                                validator(rule, value) {
                                                                    return new Promise((resolve: (value?: any) => void, reject) => {

                                                                        if (value != '' && value != null) {
                                                                            value = unMask(value);
                                                                            if (value.length >= 10) {
                                                                                resolve();
                                                                            } else {
                                                                                reject("Celular inválido");
                                                                            }
                                                                        } else {
                                                                            reject('');
                                                                        }
                                                                    });
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            size='large'
                                                            className='inputsLimitesETaxas'
                                                            prefix={<WhatsAppSVG style={{ color: '#848484' }} />}
                                                            placeholder="Celular (WhatsApp)"
                                                            onChange={onChangeInputCelular}
                                                            inputMode='numeric'
                                                        />
                                                    </Form.Item>

                                                </Col>

                                                <Col {...colFull}>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            style={{
                                                                width: "100%",
                                                                marginTop: 25,
                                                                backgroundColor: '#27A0DB',
                                                                borderWidth: 0,
                                                                borderRadius: 50
                                                            }}
                                                            size='large'
                                                            htmlType='submit'
                                                            className='font-personalizada'
                                                        >
                                                            <span style={{
                                                                fontSize: 18,
                                                                fontWeight: 'bold'
                                                            }}>Enviar</span>
                                                        </Button>
                                                    </div>

                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }

                        {
                            msgEmail && (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1
                                    }}
                                >

                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            className='divFormulario_cewolvbCOLibeofrqa divFormularioWitdhResponsivoMSG_cewolvbCOLibeofrqa'
                                        >
                                            <CheckBlueSVG style={{ alignSelf: 'center' }} />

                                            <h1
                                                style={{
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    // color: '#27AE60',
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    marginTop: 15,
                                                }}
                                                className='font-personalizadaTwo fontSizeTitleLimitesETaxas_efcwhfgkvqwe'
                                            >Agradecemos sua solicitação!</h1>
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    // fontWeight: 'bold',
                                                    // color: '#27AE60',
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    fontSize: '1.2em'
                                                }}
                                                className='font-personalizadaTwo fontSizeLimitesETaxas'
                                            >Enviamos um e-mail com os dados e o link para sua consulta.</p>
                                            <Col {...colFull}>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Button
                                                        type="primary"
                                                        style={{
                                                            width: "70%",
                                                            marginTop: 10,
                                                            backgroundColor: '#27A0DB',
                                                            borderWidth: 0,
                                                            borderRadius: 50
                                                        }}
                                                        size='large'
                                                        htmlType='submit'
                                                        className='font-personalizadaTwo fontSizeLimitesETaxas'
                                                        onClick={() => {
                                                            setMsgEmail(false);
                                                            setLoading(false)
                                                        }}
                                                    >
                                                        <span style={{
                                                            // fontSize: 18,
                                                            fontWeight: 'bold'
                                                        }}>OK</span>
                                                    </Button>
                                                </div>

                                            </Col>

                                        </div>


                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default LimitesETaxas;
