import React from 'react';

import { Button, Col, Divider, Modal, Row, Table, Tag, Typography } from 'antd';
import locale from 'antd/es/locale/pt_BR';
import LayoutDashboard from './../../layout/LayoutAdmin';

import { Loading } from '../../components/Loading';
import { useListagemPreCadastro } from './useListagemPreCadastro';
import { MenuOutlined } from '@ant-design/icons';
import { convertMoedaBRDoJSComExtamente2CasasDecimais, poeValorComPontosEmMil } from '../../services/Conversores';

const { Title, Text } = Typography;

const ListagemPreCadastro: React.FC = () => {

    const {
        loadingPage,
        columns,
        dadosTabelaState,
        refContagemDoFiltro,

        setModalViewDados,
        modalViewDados,
        dadosViewDadosInModal
    } = useListagemPreCadastro();

    return (
        <LayoutDashboard>
            <Loading
                loading={loadingPage}
            >
                <Row>
                    <Col span={24}>
                        <Title level={4}>
                            <MenuOutlined style={{ fontSize: 22, marginRight: 10 }} />
                            {"Limites e Taxas"}
                        </Title>
                        <Divider />
                    </Col>
                </Row>
                <Table
                    style={{
                        // marginTop: 50
                    }}
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    // loading={loadingTable}
                    // onRow={(record, rowIndex) => {
                    //     return {
                    //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                    //         onDoubleClick: event => { }, // double click row
                    //         onContextMenu: event => { }, // right button click row
                    //         onMouseEnter: event => { }, // mouse enter row
                    //         onMouseLeave: event => { }, // mouse leave row
                    //     };
                    // }}
                    // rowSelection={rowSelection}
                    columns={columns} dataSource={dadosTabelaState}
                    scroll={{ x: 650 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    // title={(registros) => {

                    //     return (
                    //         <div
                    //             style={{
                    //                 backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                    //                 padding: 10,
                    //                 margin: -8,
                    //                 marginBottom: 0.5
                    //             }}
                    //         >
                    //             <Text>
                    //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                    //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                    //                     dadosTabelaState.length :
                    //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                    //             </Text>
                    //         </div>
                    //     )
                    // }}
                    // footer={(registros) => {
                    //     return (
                    //         <div
                    //             style={{
                    //                 textAlign: 'end'
                    //             }}
                    //         >
                    //             <Text>
                    //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                    //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                    //                     dadosTabelaState.length :
                    //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                    //             </Text>
                    //         </div>
                    //     )
                    // }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: false,
                        defaultPageSize: 100,
                        // position: ['topRight', 'bottomRight'],
                        position: [],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </Loading>

            <Modal
                title="Dados de Pré-Cadastro"
                visible={modalViewDados}
                className="classmodalDadosContatoInatendimento"
                onOk={() => setModalViewDados(false)}
                onCancel={() => setModalViewDados(false)}
                footer={[
                    <Button key="back" onClick={() => setModalViewDados(false)}>
                        Fechar
                    </Button>,
                ]}
            >
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Município:'}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.municipio?.nome
                                        ?
                                        dadosViewDadosInModal?.municipio?.nome
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'CNPJ: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.cnpj
                                        ?
                                        dadosViewDadosInModal?.cnpj
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Nome: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.nome
                                        ?
                                        dadosViewDadosInModal?.nome
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'CPF: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.cpf
                                        ?
                                        dadosViewDadosInModal?.cpf
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'E-mail: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.email
                                        ?
                                        dadosViewDadosInModal?.email
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Telefone Fixo: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.telefoneFixo
                                        ?
                                        dadosViewDadosInModal?.telefoneFixo
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Celular: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.celular
                                        ?
                                        dadosViewDadosInModal?.celular
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Limite pré-aprovado: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.municipio?.limitePreAprovado
                                        ?
                                        'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dadosViewDadosInModal?.municipio.limitePreAprovado, true)
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Taxa de juros: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        typeof dadosViewDadosInModal?.municipio?.taxaDeJuros === 'number'
                                        ?
                                        convertMoedaBRDoJSComExtamente2CasasDecimais(dadosViewDadosInModal?.municipio.taxaDeJuros, true)
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>{'Criado em: '}</Text>
                            <Text style={{ marginLeft: 5 }}>
                                {
                                    dadosViewDadosInModal &&
                                        dadosViewDadosInModal?.createdAt
                                        ?
                                        dadosViewDadosInModal?.createdAt
                                        :
                                        ''
                                }
                            </Text>
                        </Row>
                    </Col>
                </Row>
            </Modal>

        </LayoutDashboard >
    );
}

export default ListagemPreCadastro;
