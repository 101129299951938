import moment from 'moment';

/**
 * Conversor de moeda
 */
export const convertMoedaBRParaUS = (valor: string | undefined): number | undefined => {
    if (valor) {
        return parseFloat(valor.replace(/[^0-9,.]/g, '').replace(/[.]/g, '').replace(',', '.'));
    }
    return undefined;
};

/**
 * Poe pontos do valor de number a cada mil
 */
export const poeValorComPontosEmMil = (valor: number | undefined | null): string => {

    if (valor && typeof valor === 'string') {

        let recebe = Number(valor);

        if (isNaN(recebe)) recebe = 0;

        return recebe.toLocaleString('pt-br');

    } else if (valor && typeof valor === 'number') {

        return valor.toLocaleString('pt-br');

        // return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

    }
    return '0';
};

/**
 * Conversor de moeda do JS
 * com no min duas casas decimais
 */
export const convertMoedaBRDoJSNovo = (valor: number | undefined | string): string => {
    if (valor && typeof valor === 'string') {

        let recebe = Number(valor);

        if (isNaN(recebe)) recebe = 0;

        return recebe.toLocaleString('pt-br', { minimumFractionDigits: 2 });

    } else if (valor && typeof valor === 'number') {

        return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });

        // return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

    }


    return '0,00';
};

/**
 * Conversor de moeda do JS com, tres casas decimeis
 */
export const convertMoedaBRDoJSCom3CasasDecimais = (valor: number | undefined | string): string => {
    if (valor && typeof valor === 'string') {

        let recebe = Number(valor);

        if (isNaN(recebe)) recebe = 0;

        return recebe.toLocaleString('pt-br', { minimumFractionDigits: 3 });

    } else if (valor && typeof valor === 'number') {

        return valor.toLocaleString('pt-br', { minimumFractionDigits: 3 });

        // return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

    }
    return '0';
};

/**
 * valida Digitação E Formata Dinheiro BR
 */
export const validaDigitaçãoEFormataDinheiroBR = (valor: any): string | undefined => {
    if (valor) {
        const v = ((valor.replace(/\D/g, '') / 100).toFixed(2) + '').split('.');

        const m: any = v[0].split('').reverse().join('').match(/.{1,3}/g);

        for (let i = 0; i < m.length; i++)
            m[i] = m[i].split('').reverse().join('') + '.';

        const r = m.reverse().join('');

        return r.substring(0, r.lastIndexOf('.')) + ',' + v[1];
    }
    return undefined
};

/**
 * Conversor de moeda do JS com, tres casas decimeis
 */
export const convertMoedaBRDoJSComExtamente2CasasDecimais = (valor: number | undefined | string | null, zeroOrZeroPTbr = false): string => {
    if (valor && typeof valor === 'string') {

        let recebe = Number(valor);

        if (isNaN(recebe)) recebe = 0;

        return recebe.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    } else if (valor && typeof valor === 'number') {

        return valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        // return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

    }
    if (zeroOrZeroPTbr) {

        return '0,00';
    } else {

        return '0';
    }

};

/**
 * Conversor de moeda do JS com 0 casas decimais
 */
export const convertMoedaBRDoJSComExtamente0NenhumaCasaDecimal = (valor: number | undefined | string): string => {
    if (valor && typeof valor === 'string') {

        let recebe = Number(valor);

        if (isNaN(recebe)) recebe = 0;

        return recebe.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

    } else if (valor && typeof valor === 'number') {

        return valor.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

        // return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

    }
    return '0';
};

/**
 * Conversor de data
 */
export const convertDataBrParaUS = (data: string): string | undefined => {
    if (data) {
        return moment(data, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
    }
    return undefined
};


/**
 * Conversor de data retorna escrito
 * 23 julho 2020
 */
export const convertDataUSParaBRRetornandoEscrito = (data: string): string | undefined => {
    if (data) {

        const mesesAbre = ['Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'];
        let currentDate = new Date(data + 'T14:00:00');
        let result = currentDate.getDate() + ' ' + mesesAbre[currentDate.getMonth()] + ' ' + currentDate.getFullYear()

        return result;

    }
    return undefined
};

export const funcRetornaNomeParadataComTempo = (data: string, hora: boolean = false): string | undefined => {
    if (data) {
        const mesesAbre = ['Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'];

        if (hora) {

            let momen = moment(data).format('YYYY-MM-DD')
            let momenHr = moment(data).format('HH:mm')


            let currentDate = new Date(momen + 'T14:00:00');

            let result = currentDate.getDate() + ' ' + mesesAbre[currentDate.getMonth()] + ' '
                + currentDate.getFullYear() + ' ' + momenHr

            return result;
        } else {
            let momen = moment(data).format('YYYY-MM-DD')

            let currentDate = new Date(momen + 'T14:00:00');

            let result = currentDate.getDate() + ' ' + mesesAbre[currentDate.getMonth()] + ' ' + currentDate.getFullYear()

            return result;
        }
        // return moment(data).format('DD/MM/YYYY')
    }
    return undefined
};

/**
 * Conversor de data
 */
export const convertDataUSParaBrasilEHora = (data: null | undefined | string | Date): string => {
    if (data) {
        const dataTDate = new Date(data);
        const newDate = new Intl.DateTimeFormat('pt-BR',
            {
                // timeZone: 'UTC',
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",

            }
        ).format(dataTDate);

        return newDate
    }
    return ''
};
/**
 * Conversor de datasegundo
 */
export const convertDataUSParaBrasilEHoraSegundos = (data: null | undefined | string | Date): string => {
    if (data) {
        const dataTDate = new Date(data);
        const newDate = new Intl.DateTimeFormat('pt-BR',
            {
                // timeZone: 'UTC',
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",

            }
        ).format(dataTDate);

        return newDate
    }
    return ''
};

/**
 * Conversor de data
 */
export const convertDataUSParaBrasil = (data: null | undefined | string | Date): string => {
    if (data) {
        const dataTDate = new Date(data);
        const newDate = new Intl.DateTimeFormat('pt-BR',
            {
                // timeZone: 'UTC',
                day: "2-digit",
                month: "2-digit",
                year: "numeric",

            }
        ).format(dataTDate);

        return newDate
    }
    return ''
};

/**
 * Conversor de hora timestamp
 */
export const convertHora = (data: null | undefined | string | Date): string => {
    if (data) {
        const dataTDate = new Date(data);
        const newDate = new Intl.DateTimeFormat('pt-BR',
            {
                // timeZone: 'UTC',
                hour: "2-digit",
                minute: "2-digit",

            }
        ).format(dataTDate);

        return newDate
    }
    return ''
};

/**
 * conversor de valor em min
 * 1k, 2M, 30G
 * @param num
 * @returns
 */
export const abreviarNumero = (num: number) => {

    const SI_SYMBOL = ['', 'mil', 'Mi', 'Bi', 'T', 'p', 'E', 'Z', 'Y'];
    if (num >= 0) {//numeros positivos

        const tier = Math.floor(Math.log10(num) / 3) || 0;

        let result = '' + num;

        // if zero, we don't need a suffix
        if (tier > 0) {
            // get suffix and determine scale
            const suffix = SI_SYMBOL[tier];
            const scale = Math.pow(10, tier * 3);
            // scale the number
            const scaled = num / scale;
            // format number and add suffix
            result = scaled.toFixed(1).replace('.0', '') + suffix;
        }

        return result;
    } else {//numeros negativos
        let numNegativo = Math.abs(num)

        const tier = Math.floor(Math.log10(numNegativo) / 3) || 0;

        let result = '' + numNegativo;

        // if zero, we don't need a suffix
        if (tier > 0) {
            // get suffix and determine scale
            const suffix = SI_SYMBOL[tier];
            const scale = Math.pow(10, tier * 3);
            // scale the number
            const scaled = numNegativo / scale;
            // format number and add suffix
            result = scaled.toFixed(1).replace('.0', '') + suffix;
        }

        return '-' + result;

    }
}
