import { Row, Col, Button } from 'antd';

// @ts-ignore
import ImagemLogoSPWHITE from './../../../assets/imagens/SP_DESENVOLVIMENTO_ECONOMICO_WHITE.png';
// @ts-ignore
import ImagemLogoSP from './../../../assets/imagens/SP_DESENVOLVIMENTO_ECONOMICO.png';
// @ts-ignore
import ImagemLogoDSP from './../../../assets/imagens/DESENVOLVE_SP_LOGO_EM_CIMA.png';
// @ts-ignore
import ImagemLogoDSPWhite from './../../../assets/imagens/DESENVOLVE_SP_LOGO_EM_CIMA_WHITE.png';


import './style.css';
import { Loading } from '../../../components/Loading';
import { useLimitesETxById } from './useLimitesETxById';
import { convertMoedaBRDoJSComExtamente2CasasDecimais } from '../../../services/Conversores';
import { DividerStyled } from '../../../components/DividerStyled';
import { FiClipboard } from "react-icons/fi";

const colFull = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

const col12 = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

const LimitesETxById = () => {

    const {
        loading,
        theme,
        msgEntreEmContato,
        setMsgEntreEmContato,

        data,
        textCopy,
        contextHolder
    } = useLimitesETxById();

    return (
        <>
            {contextHolder}
            <Loading
                loading={loading}
            />
            <Row
                gutter={24}
                style={{
                    // maxHeight: "100vh",
                    margin: 0,

                    display: 'flex',
                    minHeight: '100vh',
                    alignItems: 'stretch'
                }}
                align="middle"
            >

                <Col
                    className='fotoFundoPreCadastro_fvewqlefhjgvbAUTH'
                    style={{
                        filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',
                    }} flex={1}
                >
                    <div
                        className='cssformPreCadastro_cewolvbCOLibeofrqaAUTH'
                    >
                        <div
                            className={`divImgsMobile_frhvbqwyuofvbqwfAUTH ${msgEntreEmContato ? 'marginBottomResponsivo' : ''}`}
                        >
                            <img
                                className='imagemLogo_cewolvbCOLibeofrqaAUTH'
                                src={ImagemLogoDSPWhite}
                            ></img>
                            <img
                                className='imagemLogoSP_cewolvbCOLibeofrqaAUTH'
                                src={ImagemLogoSPWHITE}
                            ></img>
                        </div>

                        {
                            data && (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1
                                    }}
                                >

                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            className='divFormulario_cewolvbCOLibeofrqaAUTH divFormularioWitdhResponsivo_cewolvbCOLibeofrqaAUTH'
                                        >
                                            {/* <FiCheckCircle size={120} style={{ alignSelf: 'center' }} color='#27AE60' /> */}

                                            <Row>

                                                <Col {...colFull}>
                                                    <h1
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                            lineHeight: 'normal',
                                                            // color: '#FFF',
                                                            // marginLeft: 10,
                                                            margin: 1,
                                                            marginBottom: 10
                                                            // fontSize: '1.2rem'
                                                        }}
                                                        className='font-personalizadaAUTH'
                                                    >
                                                        <span
                                                            style={{
                                                                textAlign: 'center',
                                                                fontWeight: 'bold',
                                                                // color: '#FFF',
                                                                // marginLeft: 10,
                                                                // marginRight: 10,
                                                            }}
                                                            className='font-personalizadaAUTH quebraDeLinhaSpan fontSizeTitle_efcwhfgkvqweAUTH'
                                                        >
                                                            {'Visualização do Resultado'}
                                                        </span>
                                                    </h1>
                                                </Col>

                                                <Col {...colFull}>
                                                    <p
                                                        style={{
                                                            // textAlign: 'center',
                                                            // fontWeight: 'bold',
                                                            // color: '#FFF',
                                                            // marginLeft: 10,
                                                            margin: 1,
                                                            // fontSize: '1.2rem'
                                                        }}
                                                        className='font-personalizadaAUTH fontSizeAUTH'
                                                    >
                                                        {'Município: '}
                                                        <span
                                                            style={{
                                                                // textAlign: 'center',
                                                                fontWeight: 'bold',
                                                                // color: '#FFF',
                                                                // marginLeft: 10,
                                                                // marginRight: 10,
                                                            }}
                                                            className='font-personalizadaAUTH'
                                                        >
                                                            {data && data?.municipio.nome}
                                                        </span>
                                                    </p>
                                                </Col>


                                                <Col {...colFull}>
                                                    <p
                                                        style={{
                                                            // textAlign: 'center',
                                                            // fontWeight: 'bold',
                                                            // color: '#FFF',
                                                            // marginLeft: 10,
                                                            margin: 1,
                                                            // fontSize: '1.2rem'
                                                        }}
                                                        className='font-personalizadaAUTH fontSizeAUTH'
                                                    >
                                                        {'Limite pré-aprovado: '}
                                                        <span
                                                            style={{
                                                                fontWeight: 'bold',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                            className='font-personalizadaAUTH'
                                                        >
                                                            {data && 'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(data?.municipio.limitePreAprovado, true)}
                                                        </span>
                                                    </p>

                                                </Col>

                                                <Col {...colFull}>
                                                    <p
                                                        style={{
                                                            // textAlign: 'center',
                                                            // fontWeight: 'bold',
                                                            // color: '#FFF',
                                                            // marginLeft: 10,
                                                            margin: 1,
                                                            // fontSize: '1.2rem'
                                                        }}
                                                        className='font-personalizadaAUTH fontSizeAUTH'
                                                    >
                                                        {'Taxa de juros: '}

                                                        <span
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: 'bold',
                                                            }}
                                                            className='font-personalizadaAUTH'
                                                        >
                                                            {data && convertMoedaBRDoJSComExtamente2CasasDecimais(data?.municipio.taxaDeJuros, true) + "% a.a."}
                                                        </span>
                                                    </p>

                                                </Col>

                                                <Col {...colFull}>
                                                    <h4
                                                        style={{
                                                            fontWeight: 'bold',
                                                            margin: 1,
                                                            // fontSize: '1.2rem'
                                                        }}
                                                        className='font-personalizadaAUTH fontSizeh4AUTH'
                                                    >
                                                        {'* As condições do financiamento poderão ser alteradas sem aviso prévio.'}
                                                    </h4>

                                                </Col>

                                                <Col {...colFull}>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <Button
                                                            type={msgEntreEmContato ? "default" : 'primary'}
                                                            style={{
                                                                width: "100%",
                                                                marginTop: 25,
                                                                backgroundColor: msgEntreEmContato ? undefined : '#27A0DB',
                                                                borderWidth: 0,
                                                                borderRadius: 50
                                                            }}
                                                            size='large'
                                                            htmlType='submit'
                                                            className='font-personalizada fontSizeAUTH'
                                                            onClick={() => { setMsgEntreEmContato((val) => !val) }}
                                                        >
                                                            <span style={{
                                                                // fontSize: 16,
                                                                fontWeight: 'bold'
                                                            }}>Entre em contato!</span>
                                                        </Button>
                                                    </div>

                                                </Col>

                                                {
                                                    msgEntreEmContato && (
                                                        <>
                                                            <Col {...colFull} style={{
                                                                marginTop: 15,
                                                                marginBottom: 15
                                                            }}>
                                                                <DividerStyled />
                                                            </Col>
                                                            <Col {...colFull}>
                                                                <p
                                                                    style={{
                                                                        margin: 1,
                                                                    }}
                                                                    className='font-personalizadaAUTH fontSizeAUTH'
                                                                >
                                                                    {'Nome: '}
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        className='font-personalizadaAUTH quebraDeLinhaSpan'
                                                                    >
                                                                        {"Billy Matias - Gerente de Negócios do Setor Público"}
                                                                    </span>
                                                                </p>

                                                            </Col>
                                                            <Col {...colFull}
                                                                style={{
                                                                    marginTop: 5,
                                                                    marginBottom: 5
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        margin: 1,
                                                                    }}
                                                                    className='font-personalizadaAUTH fontSizeAUTH'
                                                                >
                                                                    {'E-mail: '}
                                                                    <span
                                                                        onClick={() => { textCopy('billy.matias@desenvolvesp.com.br') }}
                                                                        style={{
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        className='font-personalizadaAUTH quebraDeLinhaSpan break-word cursorClick'
                                                                    >
                                                                        {"billy.matias@desenvolvesp.com.br "}
                                                                        <FiClipboard />
                                                                        <br />
                                                                    </span>

                                                                    <span
                                                                        onClick={() => { textCopy('negocios.publico@desenvolvesp.com.br') }}
                                                                        style={{
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        className='font-personalizadaAUTH quebraDeLinhaSpan break-word cursorClick'
                                                                    >
                                                                        {"negocios.publico@desenvolvesp.com.br "}
                                                                        <FiClipboard />
                                                                    </span>
                                                                </p>

                                                            </Col>
                                                            <Col {...colFull}>
                                                                <p
                                                                    style={{
                                                                        margin: 1,
                                                                    }}
                                                                    className='font-personalizadaAUTH fontSizeAUTH'
                                                                >
                                                                    {'Tel: '}
                                                                    <span
                                                                        onClick={() => { textCopy('1131236103') }}
                                                                        style={{
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        className='font-personalizadaAUTH quebraDeLinhaSpan cursorClick'
                                                                    >
                                                                        {"(11) 3123-6103 "}
                                                                        <FiClipboard />
                                                                    </span>
                                                                </p>

                                                            </Col>
                                                        </>
                                                    )
                                                }
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default LimitesETxById;
