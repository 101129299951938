// const urlHttpGov = process.env.NODE_ENV === 'development' ? 'https://api.govfacilbrasil.com.br' : 'https://api.govfacil.app';

export const urlHttpGov = typeof process.env?.REACT_APP_API_URL === 'string' ? process.env?.REACT_APP_API_URL : 'http://localhost:3333';
export const urlHttpGovSocketIo = typeof process.env?.REACT_APP_API_WEB_SOCKET === 'string' ? process.env?.REACT_APP_API_WEB_SOCKET : 'http://localhost:3333';

// export const urlHttpGov = 'https://desenvolveapi.govfacilbrasil.com.br';//homologacao
// export const urlHttpGovSocketIo = 'wss://desenvolveapi.govfacilbrasil.com.br';//homologacao

// export const urlHttpGov = 'https://desenvolveapi.govfacil.app';//producao
// export const urlHttpGovSocketIo = 'wss://desenvolveapi.govfacil.app';//producao




//SESSAO
export const urlFileServerGovFacilApp_FontesCauc = 'https://fileserver.govfacil.app/fontes-cauc'

export const urlUsuarioSession = urlHttpGov+'/usuarios/session'
export const urlUsuariosSessionRefreshToken = urlHttpGov+'/usuarios/session/refresh-token'

//usuario regra
export const urlUsuariosRegra = urlHttpGov+'/usuarios/regra'
export const urlUsuariosPermissao = urlHttpGov+'/usuarios/permissao'
export const urlUsuariosUsuarioPerfil = urlHttpGov+'/usuarios/usuario/perfil'

export const urlUsuariosUsuario = urlHttpGov+'/usuarios/usuario'
// /email para consultar
export const urlUsuariosUsuarioEmail = urlHttpGov+'/usuarios/usuario/email'
export const urlUsuariosUsuarioAtivos = urlHttpGov+'/usuarios/usuario/ativos'
export const urlUsuariosUsuarioInativos = urlHttpGov+'/usuarios/usuario/inativos'
export const urlUsuariosUsuarioPrimeiroAcesso = urlHttpGov+'/usuarios/usuario/primeiro_acesso'
export const urlUsuariosUsuarioRecuperacao = urlHttpGov+'/usuarios/usuario/recuperacao'
export const urlUsuariosUsuarioUpdate = urlHttpGov+'/usuarios/usuario/update'


export const urlLimitesETaxasMunicipios = urlHttpGov+'/limites-e-taxas/municipios'
export const urlLimitesETaxas = urlHttpGov+'/limites-e-taxas'

export const urlMunicipioMunicipios = urlHttpGov+'/municipio/municipios'
export const urlMunicipioEleicao = urlHttpGov+'/municipio/eleicao'
export const urlMunicipioStatus = urlHttpGov+'/municipio/status'
export const urlMunicipioCertidao = urlHttpGov+'/municipio/certidao'
export const urlMunicipioCertidaoPdfsLiberacao = urlHttpGov+'/municipio/certidao/pdfs/liberacao'
export const urlMunicipioCertidaoPdfs = urlHttpGov+'/municipio/certidao/pdfs'
export const urlMunicipioArquivoUpload = urlHttpGov+'/municipio/arquivo/upload'
export const urlMunicipioArquivoUploadSeade = urlHttpGov+'/municipio/arquivo/upload/seade'
export const urlMunicipioArquivoUploadSaneamento = urlHttpGov+'/municipio/arquivo/upload/saneamento'
export const urlCongelamentoMunicipio = urlHttpGov+'/congelamento/municipio'
export const urlMunicipioSaneamentoRespostas = urlHttpGov+'/municipio/saneamento/respostas'
export const urlCongelamento = urlHttpGov+'/congelamento'
export const urlCongelamentoCompleto = urlHttpGov+'/congelamento/completo'
export const urlCongelamentos = urlHttpGov+'/congelamentos'
export const urlPoliticosEleicao = urlHttpGov+'/politicos/eleicao'

export const urlPoliticosPoliticoNoticia = urlHttpGov+'/politicos/politico/noticia'
export const urlPoliticosPoliticoObservacao = urlHttpGov+'/politicos/politico/observacao'
export const urlPoliticosPoliticoSegmento = urlHttpGov+'/politicos/politico/segmento'
export const urlPoliticosProcesso = urlHttpGov+'/politicos/processo'
export const urlPoliticosPolitico = urlHttpGov+'/politicos/politico'

export const urlMunicipioStatusConsolidado = urlHttpGov+'/municipio/status/consolidado'
export const urlMunicipioStatusConsolidadoPdf = urlHttpGov+'/municipio/status/consolidado/pdf'
export const urlMunicipioStatusConsolidadoDataFiltro = urlHttpGov+'/municipio/status/consolidado/datafiltro'
export const urlMunicipioStatusConsolidadoPDFMunicipios = urlHttpGov+'/municipio/status/consolidado/pdf/municipios'
export const urlMunicipioStatusMunicipios = urlHttpGov+'/municipio/status/municipios'
export const urlMunicipioStatusMunicipiosXlsx = urlHttpGov+'/municipio/status/municipiosXlsx'
export const urlMunicipioObrigacoesTransparencia = urlHttpGov+'/municipio/obrigacoes/transparencia'
export const urlPoliticos = urlHttpGov+'/politicos'
export const urlPoliticosFiles = urlHttpGov+'/politicos-files'
export const urlCertidoes = urlHttpGov+'/certidoes'
export const urlPoliticosPoliticoUltimaEleicao = urlHttpGov+'/politicos/politico/ultima_eleicao'

//configuração de limite de operações
export const urlLimiteOperacoesIndexador = urlHttpGov+'/limite_operacoes/indexador'
export const urlLimiteOperacoesProduto = urlHttpGov+'/limite_operacoes/produto'
export const urlLimiteOperacoesStatus = urlHttpGov+'/limite_operacoes/status'
export const urlLimiteOperacoesObjeto = urlHttpGov+'/limite_operacoes/objeto'
export const urlLimiteOperacoesPosicao = urlHttpGov+'/limite_operacoes/posicao'
export const urlLimiteOperacoesLimite = urlHttpGov+'/limite_operacoes/limite'
export const urlLimiteOperacoesImportacao = urlHttpGov+'/limite_operacoes/importacao'
export const urlLimiteOperacoesRelatorio = urlHttpGov+'/limite_operacoes/relatorio'
export const urlLimiteOperacoesImportacaoReprocessa = urlHttpGov+'/limite_operacoes/importacao/reprocessa'
export const urlLimiteOperacoesImportacaoUltima = urlHttpGov+'/limite_operacoes/importacao/ultima'
export const urlLimiteOperacoesImportacaoDownload = urlHttpGov+'/limite_operacoes/importacao/download'
